import { actionTypes } from '../actions/aboutus.action'

const initialState = {
  error: null,
  isLoading: false,
  aboutUs_data: null
}

const aboutUs = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETABOUTUS_REQUEST: // getAboutUs
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETABOUTUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        aboutUs_data: action.data
      }
    case actionTypes.GETABOUTUS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default aboutUs