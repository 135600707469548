import { actionTypes } from '../actions/promotion.action'

const initialState = {
  error: null,
  isLoading: false,
  promotion_data: null,
  promotion_detail_data: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETPROMOTION_REQUEST: // getPromotion
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPROMOTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotion_data: action.data
      }
    case actionTypes.GETPROMOTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPROMOTIONBYID_REQUEST: // getPromotionById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPROMOTIONBYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotion_detail_data: action.data
      }
    case actionTypes.GETPROMOTIONBYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETFIXEDPRICEPROMOTION_REQUEST: // getFixedPricePromotion
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETFIXEDPRICEPROMOTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotion_detail_data: action.data
      }
    case actionTypes.GETFIXEDPRICEPROMOTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}