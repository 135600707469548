import { intervalToDuration, isToday } from "date-fns"

const setOrder = data => {
  if (data) {
    data.createdDate = new Date(Date.now())
  }
  localStorage.setItem('zizawar_order_store', JSON.stringify(data))
}

const getOrder = () => {
  let tmp = JSON.parse(localStorage.getItem('zizawar_order_store'))
  if (tmp) {
    var checkToday = isToday(new Date(tmp?.createdDate))
    if (checkToday) {
      return tmp
    } else {
      removeOrder()
    }
  } else {
    return tmp
  }
}

const removeOrder = () => {
  localStorage.removeItem('zizawar_order_store')
}

export default {
  setOrder, getOrder, removeOrder
}