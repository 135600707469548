import moment from 'moment'
import { orderStore } from '../service'

import { Ecommerce } from '../store/actions/ecommerce.action'
import { en2mm_num } from './utils'

/**
 * * This function calculate promotion for product list
 * todo: Let's calculate the promotion price | insert it and return
 * @param data is product list of reducer state
 */
const calcuPromo = data => {
  if (data?.product_list?.length > 0) {
    let temp = []
    let res = data?.product_list?.map((x, i) => {
      if (x?.promotion?.length > 0) {
        let tmp = isBetweenDateRange(x.promotion, 'discount')
        if (tmp.length > 0) {
          let add = tmp.reduce((total, value) => total + value.discount_percent, 0)
          let km = x.list_price * (add / 100)
          let fr = x.list_price - km
          x.promo_price = fr
        }
        if (!x?.qty) x.qty = 1
        temp.push(x)
      } else {
        if (!x?.qty) x.qty = 1
        x.promo_price = false
        temp.push(x)
      }
    })
    return data
  } else {
    return data
  }
}

/**
 * * This function calculate promotion for product detail
 * todo: Let's calculate the requirements related to promotion | insert it and return
 * @param data is product_detail of reducer state
 * @param qty is product_detail qty
 */
const productPromo = (data, qty, lang, customer_type) => {
  //* check discount promotion for calculate discount price
  let tmp = isBetweenDateRange(data?.promotion, 'discount')

  //* check product promotion for priority
  //* check current product promotion
  let ptm = isBetweenDateRange(data?.promotion, 'product')
  if (ptm?.length > 0) {
    data.promo_product = ptm
  }
  let isExistProductPromo = ptm.length > 0 && ptm?.some(x => x.buy_product[0].min_qty <= qty)

  //* check current package discount promotion
  let package_ptm = isBetweenDateRange(data?.promotion, 'packagediscount')
  if (package_ptm?.length > 0) {
    data.package_promo = package_ptm
  }

  //* check fixed price promotion
  let fixed_price_ptm = isBetweenDateRange(data?.promotion, 'fixed_price')
  if (fixed_price_ptm?.length > 0) {
    data.fixed_price_promo = fixed_price_ptm
  }

  /**
   * * When calculating a promotion, what is the price based on? It is important. So
   * todo: Let's calculate the currentBasePrice first
   */
  let base_price = data?.list_price?.length > 0 && data?.list_price?.sort((a, b) => a?.min_quantity - b?.min_quantity)?.filter(x => x?.min_quantity <= qty)
  data.currentBasePrice = base_price?.length > 0 ? base_price[base_price.length - 1] : 0

  if (isExistProductPromo) {
    let check_baseprice = data?.list_price?.length > 0 && data?.list_price?.filter(x => x?.min_quantity === 1)
    if (check_baseprice?.length > 0) {
      data.currentBasePrice = check_baseprice[0]
    }
  }

  if (tmp?.length > 0) {
    let check_baseprice = data?.list_price?.length > 0 && data?.list_price?.filter(x => x?.min_quantity === 1)
    if (check_baseprice?.length > 0) {
      data.currentBasePrice = check_baseprice[0]
    }

    if (tmp?.length > 0) {
      let add = tmp.reduce((total, value) => total + value?.discount_percent, 0)
      let km = data?.currentBasePrice?.fixed_price * (add / 100)
      let fr = data?.currentBasePrice?.fixed_price - km
      data.promo_price = fr
      data.saving_price = data?.currentBasePrice?.fixed_price - fr
      data.totalPromoDiscount = add
      data.totalCurrentPrice = qty * fr
    }
  } else {
    data.promo_price = false
    data.saving_price = false
    data.totalPromoDiscount = false
    data.totalCurrentPrice = qty * data?.currentBasePrice?.fixed_price
  }

  //* check all current promotion
  data.receivedPromo = [...tmp, ...ptm, ...package_ptm, ...fixed_price_ptm]

  //* calculate promotion qty | without check variant_id
  if (data.receivedPromo?.length > 0) {
    let result = data.receivedPromo?.map(x => {
      let buy_res = x.buy_product?.length > 0 && x.buy_product?.map(b => {
        let tem = x.reward_product?.length > 0 && x.reward_product?.map(y => {
          let all_qty = + b.min_qty
          let free_qty = + y.reward_qty
          x.seconday_desc = lang === 'en' ?
            `You may receive additional ${free_qty} free gift${free_qty > 1 ? 's' : ''} for purchasing every ${all_qty} unit${all_qty > 1 ? 's' : ''}.`
            :
            `ပစ္စည်း ${en2mm_num(all_qty)} ခုကိုလှည်းထဲသို့ထည့်ပါ၊ ${en2mm_num(free_qty)} ခုအခမဲ့ရလိမ့်မည်။`
        })
      })
    })
  }

  if (customer_type) data.customer_type = customer_type?.customer_type

  return data
}

const breadcrumb = async data => {
  if (data?.data?.length > 0) {
    if (data?.data[0]?.sub_category?.length > 0) {
      let tmp = {
        ...data?.data[0],
        selectIndex: `${data?.data?.indexOf(data?.data[0])}-${data?.data[0]?.sub_category?.indexOf(data?.data[0]?.sub_category[0])}`,
        sub_category: [data?.data[0]?.sub_category[0]]
      }
      data.breadcrumbData = tmp
    } else {
      data.data[0].selectIndex = `${data?.data?.indexOf(data?.data[0])}-0`
      data.breadcrumbData = data?.data[0]
    }
    return data
  }
}

/**
 * * start product add to cart process
 * @param postData
 * @param product_detail
 * @param langCode
 * @param dispatch
 */
const addToCart = (postData, product_detail, langCode, history, check_auto_fill) => async dispatch => {

  //* if you does not have order_id in orderStore_data, working this function
  if (!postData?.order_id) {
    delete postData.order_id
    delete postData?.sale_order_line[0]?.cart_status
  }

  //* if you does not have uid in authStore?.getAuth(), working this function
  if (!postData?.user_id) {
    delete postData.user_id
  }

  console.log(`postData`, postData)

  //* calculate promotion qty | without check variant_id
  if (check_auto_fill === 'auto_fill') {
    if (product_detail?.promo_product?.length > 0) {
      let tmp = postData?.sale_order_line[0]?.qty
      let temp_arr = product_detail?.promo_product?.filter(x => x?.buy_product[0].min_qty <= parseInt(tmp)).sort((a, b) => b.buy_product[0].min_qty - a.buy_product[0].min_qty)[0]
      // console.log(`temp_arr`, temp_arr)
      if (temp_arr) {
        if (temp_arr.buy_product?.length > 0) {
          let buy_res = temp_arr.buy_product?.map(b => {
            if (b.product_template_id === parseInt(postData?.sale_order_line[0]?.product_id) && tmp >= b.min_qty) {
              let tem = temp_arr.reward_product?.length > 0 && temp_arr.reward_product?.map(y => {
                let pushData = {
                  product_id: y.product_template_id,
                  cart_status: 'add_cart',
                  qty: Math.floor(tmp / b.min_qty) * y.reward_qty
                }
                // console.log(`Math.floor(tmp / b.min_qty) * y.reward_qty`, Math.floor(tmp / b.min_qty) * y.reward_qty)
                return pushData?.qty > 0 && postData?.sale_order_line.push(pushData)
              })
            }
          })
        }
      }
      // let tmp = postData?.sale_order_line[0]?.qty
      // let temp_arr = product_detail?.promo_product?.filter(x => x?.buy_product[0].min_qty <= parseInt(tmp)).sort((a, b) => b.buy_product[0].min_qty - a.buy_product[0].min_qty)
      // let result = temp_arr?.map(x => {
      //   if (x.buy_product?.length > 0) {
      //     let buy_res = x.buy_product?.map(b => {
      //       if (b.product_template_id === +postData?.sale_order_line[0]?.product_id && tmp >= b.min_qty) {
      //         let tem = x.reward_product?.length > 0 && x.reward_product?.map(y => {
      //           let pushData = {
      //             product_id: y.product_template_id,
      //             cart_status: 'add_cart',
      //             qty: Math.floor((b.min_qty / tmp) * y.reward_qty)
      //           }
      //           return pushData?.qty > 0 && postData?.sale_order_line.push(pushData)
      //         })
      //       }
      //     })
      //   }
      // })
    }
  }

  //* if postData?.sale_order_line is longer than 1, it has product promotion
  if (postData?.sale_order_line?.length > 1) {
    let product_promo_res = await dispatch(handleProductPromo(postData, product_detail, langCode, history))
    return product_promo_res
  } else {

    //* ဒါက product promotion မရှိတဲ့ အရိုးရှင်းဆုံး add to cart func
    let simple_res = await dispatch(handleSimplePromo(postData, product_detail, langCode, history))
    return simple_res
  }
}

const handleProductPromo = (postData, product_detail, langCode, history) => async dispatch => {

  /**
   * * sale_order_line ရဲ့ ပထမဆုံး array obj အပေါ်မူတည်းပြီး promotion product ရတဲ့အတွက် ၊ 
   * todo: sale_order_line ရဲ့ ပထမဆုံး array obj နဲ့ အရင်ဆုံး တစ်ခါ  api request လုပ်
   */
  let first_postProd = {
    ...postData,
    sale_order_line: postData?.sale_order_line?.slice(0, 1)
  }

  /**
   * todo: sale_order_line ရဲ့ ပထမဆုံး obj နဲ့ အရင်ဆုံး တစ်ခါ  api request လုပ်
   * todo: အောင်မြင်မှ သူ့နဲ့သက်ဆိုင်တဲ့ promotion product တစ်ခုချင်း တစ်ခုချင်  api request လုပ်
   */
  let res = await dispatch(Ecommerce.createCart(first_postProd, postData?.user_id, langCode, history))
  if (res?.error) {
    let alert_res = { isModal: true, ui: 'cart_info', msg: langCode === 'en' ? 'something wrong!' : 'တစ်စုံတစ်ခု မှားယွင်းနေပါသည်။', type: 'out_of_stock', status: 'error' }
    return alert_res
  } else {
    /**
     * * confirm_order_state
     */
    if (res?.result?.status === 'fail') {
      if (res?.result?.code === 'order_confirmed_state') {
        orderStore.removeOrder()
        let temp = {
          ...postData,
          order_id: null
        }
        await dispatch(addToCart(temp, product_detail, langCode, history))
        // return alert_res
      } else {

      }
    }

    /**
     * * sale_order_line ရဲ့ ပထမဆုံး array obj နဲ့ အရင်ဆုံး တစ်ခါ  api request အောင်မြင်
     * todo: sale_order_line ရဲ့ ပထမဆုံး array obj မပါတဲ့ ကျန်ရှိ  array obj တွေ တစ်ခုချင်းစီ loop လုပ်ပီး api request လုပ်
     * * တစ်ခုချင်းစီ loop လုပ်ပီး api request လုပ်တာမျိုးကို promise all method သုံး
     */
    let qty_calc = 0
    let PromiseApi = postData?.sale_order_line?.slice(1, postData?.sale_order_line.length).map(async y => {
      qty_calc += y.qty
      let sec_postProd = {
        ...postData,
        order_id: res?.result[0]?.order_id,
        sale_order_line: [y]
      }

      let result = await dispatch(Ecommerce.createCart(sec_postProd, postData?.user_id, langCode, history))

      //* ဘယ် sale_order_line array obj ရဲ့ qty က ဘယ် api request ထွက်လာလဲ သိချင်လို့ y ကို result နဲ့ ပေါင်းထဲ့ပေးလိုက်တယ်
      return {
        ...y,
        result
      }
    })
    let promise_res = Promise.all(PromiseApi).then(async value => {

      /**
       * * တစ်ခုချင်းစီ loop လုပ်ပီး api request အားလုံး လုပ်လို့ပြီးသွားတဲ့ အခါမှ res အနေနဲ့ value array ရတယ်
       * * ဒီ value array ထဲက res result status အားလုံး success ဖြစ်ရင် promotion product process က အပြည့်အ၀ အောင်မြင်တယ်
       */
      if (value?.length > 0 && value.every(x => x?.result?.result[0]?.status === 'success')) {
        let alert_res = { isModal: true, ui: 'cart_info', msg: `You get ${qty_calc} more Free item for promotion!`, type: 'in_stock', status: 'success' }
        return alert_res
      } else {
        /**
         * * promotion product process က အပြည့်အ၀ မအောင်မြင် ပေမယ့် 
         * todo: ကိုယ့်မှာ ရှိသလောက် product ကို promotion ပေးဖို့အတွက် qty ကို ပြန်တွက်
         */
        let fail_res = value?.length > 0 && value.filter(x => x?.result?.result[0]?.status === 'fail')

        //! utils fun ထုတ်လိုက်လို့သာ getCart api request ကို ဒီfunctionထဲ ထည့်ရေးတာ ၊
        //! ပုံမှန်ဆို createCart လုပ်ပီးတိုင်း getCart api request ကို action ထဲမှာကတည်းက ခေါ်ထားပီးသာ:
        let getCart_Data = await dispatch(Ecommerce.getCart(res?.result[0]?.order_id, postData?.user_id || '', langCode))

        //* ကိုယ့်မှာရှိသလောက် productပဲ promotion ပေးဖို့အတွက် qty ကို initial zero အနေနဲ့ ပြန်ထားလိုက်တယ်
        let checkQty = 0
        let PromiseApi2 = fail_res?.map(async y => {

          /**
           * * qty ဘယ်လောက်ပဲပေးနိုင်လဲ တွက်ဖို့အတွက်
           * * getCart_Data ထဲမှာ ရှိတဲ့ product_template_id နဲ့ create cart res တစ်နည်းအားဖြင့် fail_res ထဲမှာ ရှိတဲ့ product_id တူဖို့လိုတယ်
           * * အဲလိုပဲ product variant_id တွေလည်း တူဖိုလိုတယ် ပြီးရင်
           * todo: product_id တို့ variant_id တို့ တူတဲ့ checkID_Variant_Qty array ကို ထုတ်
           */
          let checkID_Variant_Qty = getCart_Data?.data?.length > 0 && getCart_Data?.data?.filter(m => (m.product_template_id === y.product_id) && (y.variant_id ? (m.variant_id === y.variant_id) : true))

          /**
           * todo: promotion product qty ဘယ်လောက်ပဲ ပေးနိုင်တယ်ဆိုတာကို ဆက်တွက်
           * * တွက်နည်း
           * todo: create cart လုပ်လို့ ရလာတဲ့ res ထဲက တစ်နည်းအာဖြင့် သက်ဆိုင်သော fail_res item (eg. y) ထဲကနေ checkID_Variant_Qty[0].quantity ကို နုတ်ရင် ရတဲ့ တန်ဖိုး
           */
          checkQty = y?.result?.result[0]?.available_qty - (checkID_Variant_Qty?.length > 0 ? checkID_Variant_Qty[0]?.quantity : 0)

          /**
           * * checkQty က zero ထက်ကျော်တယ်ဆိုရင် ကျော်သလောက်တန်ဖိုးက ကျနော်တို့ လက်ကျန်ပေးနိုင်တဲ့ promotion product qty ဖြစ်တယ်
           * * zero နဲ့ ညီနေရင်တော့ တစ်ခုမှ promotion product အနေနဲ့ မရပေမယ့်
           * * main product တော့ add to cart process အောင်မြင်တဲ့ သဘော
           */
          if (checkQty >= 0) {
            y.qty = checkQty
            let third_postProd = {
              ...postData,
              order_id: res?.result[0]?.order_id,
              sale_order_line: [{ ...y }]
            }
            delete third_postProd.sale_order_line[0].result
            let temp1 = await dispatch(Ecommerce.createCart(third_postProd, postData?.user_id, langCode, history))

            //? for testing
            // return temp1
            return {
              insufficient_qty: checkQty,
              result: temp1
            }
          }
        })
        let sec_promise_res = Promise.all(PromiseApi2).then(async value => {
          if (value?.length > 0 && value.every(x => x?.result?.result[0]?.status === 'success')) {
            let alert_res = { isModal: true, ui: 'cart_info', msg: checkQty === 0 ? `Sorry, stock is not enough. So, you can not get any items for this promotion!` : `Sorry, stock is not enough. So you can get some items as we have for this promotion!`, type: 'in_stock', status: 'success' }
            return alert_res
          } else {
            let alert_res = { isModal: true, ui: 'cart_info', msg: 'currently_out_of_stock', type: 'out_of_stock', status: 'error' }
            return alert_res
          }
        })

        return sec_promise_res
      }
    })
    return promise_res
  }
}

const handleSimplePromo = (postData, product_detail, langCode, history) => async dispatch => {
  let res = await dispatch(Ecommerce.createCart(postData, postData?.user_id, langCode, history))
  if (res?.error) {
    let alert_res = { isModal: true, ui: 'cart_info', msg: langCode === 'en' ? 'something wrong!' : 'တစ်စုံတစ်ခု မှားယွင်းနေပါသည်။', type: 'out_of_stock', status: 'error' }
    return alert_res
  } else {
    if (res?.result?.status === 'fail') {
      if (res?.result?.code === 'order_confirmed_state') {
        orderStore.removeOrder()
        let temp = {
          ...postData,
          order_id: null
        }
        await dispatch(addToCart(temp, product_detail, langCode, history))
      } else {

      }
    }
    if (res?.result?.[0]?.status === 'fail') {
      if (res?.result?.[0]?.in_cart_qty) {
        let alert_res = { isModal: true, ui: 'cart_info', msg: langCode === 'en' ? `You already have ${res?.result?.[0]?.in_cart_qty} items in your cart, so you can no longer buy them.` : `မင်းရဲ့လှည်းမှာ ဒီပစ္စည်း ${en2mm_num(res?.result?.[0]?.in_cart_qty)} ခုရှိပြီးသားဖြစ်သောကြောင့် ထပ်မံ၀ယ်လို့မရတော့ပါဘူး။`, type: 'out_of_stock', status: 'error' }
        return alert_res
      } else {
        let alert_res = { isModal: true, ui: 'cart_info', msg: 'currently_out_of_stock', type: 'out_of_stock', status: 'error' }
        return alert_res
      }
    } else {
      let alert_res = { isModal: true, ui: 'cart_info', msg: langCode === 'en' ? 'Successfully add to cart!' : 'စျေးခြင်းထဲသို့ အောင်မြင်စွာထည့်ပြီးပါပြီ။', type: 'in_stock', status: 'success' }
      return alert_res
    }
  }
}
//* end product add to cart process

/**
 * ? Is it on the specified date?
 * * This function will do it
 */
const isBetweenDateRange = (array, type) => {
  if (array.length > 0) {
    if (type) {
      let filter_arr = array?.filter(x => x.promotion_type === type)
      let tmp = filter_arr?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false) || (e.start_date !== false && e.end_date === false) || ((e.start_date === false && e.end_date !== false) && moment(moment(e.server_date_time).format()).isBetween(moment(moment().subtract(1, 'day').format()).format(), moment(e.end_date).format())) || (e.start_date === null && e.end_date === null) || (e.start_date !== null && e.end_date === null) || ((e.start_date === null && e.end_date !== null) && moment(moment(e.server_date_time).format()).isBetween(moment(moment().subtract(1, 'day').format()).format(), moment(e.end_date).format())))
      return tmp
    } else {
      let temp = array?.filter(e => moment(moment(e.server_date_time).format()).isBetween(moment(e.start_date).format(), moment(e.end_date).format()) || (e.start_date === false && e.end_date === false) || (e.start_date !== false && e.end_date === false) || ((e.start_date === false && e.end_date !== false) && moment(moment(e.server_date_time).format()).isBetween(moment(moment().subtract(1, 'day').format()).format(), moment(e.end_date).format())) || (e.start_date === null && e.end_date === null) || (e.start_date !== null && e.end_date === null) || ((e.start_date === null && e.end_date !== null) && moment(moment(e.server_date_time).format()).isBetween(moment(moment().subtract(1, 'day').format()).format(), moment(e.end_date).format())))
      return temp
    }
  } else {
    return array
  }
}

export {
  calcuPromo,
  productPromo,
  breadcrumb,
  addToCart,
  handleProductPromo,
  handleSimplePromo,
  isBetweenDateRange
}