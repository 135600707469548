import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  CITYLIST: 'CITYLIST',

  // getCITYLIST
  GETCITYLIST_REQUEST: 'GETCITYLIST_REQUEST',
  GETCITYLIST_ERROR: 'GETCITYLIST_ERROR',
  GETCITYLIST_SUCCESS: 'GETCITYLIST_SUCCESS'
}

const getCityList = (lang, dc_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCITYLIST_REQUEST))
  return await serviceController(`${routes.getCityList}?lang=${lang}&dc_type=${dc_type}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETCITYLIST_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCITYLIST_ERROR, error.message)))
}

export const cityList = {
  getCityList
}