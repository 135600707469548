import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'
import { authStore, orderStore } from '../../service'

export const actionTypes = {
  AUTH: 'AUTH',

  // SENDSIGNIN
  SENDSIGNIN_REQUEST: 'SENDSIGNIN_REQUEST',
  SENDSIGNIN_ERROR: 'SENDSIGNIN_ERROR',
  SENDSIGNIN_SUCCESS: 'SENDSIGNIN_SUCCESS',

  // faceBookSignIn
  FACEBOOKSIGNIN_REQUEST: 'FACEBOOKSIGNIN_REQUEST',
  FACEBOOKSIGNIN_ERROR: 'FACEBOOKSIGNIN_ERROR',
  FACEBOOKSIGNIN_SUCCESS: 'FACEBOOKSIGNIN_SUCCESS',

  // SENDSIGNUP
  SENDSIGNUP_REQUEST: 'SENDSIGNUP_REQUEST',
  SENDSIGNUP_ERROR: 'SENDSIGNUP_ERROR',
  SENDSIGNUP_SUCCESS: 'SENDSIGNUP_SUCCESS',

  // SENDOTP
  SENDOTP_REQUEST: 'SENDOTP_REQUEST',
  SENDOTP_ERROR: 'SENDOTP_ERROR',
  SENDOTP_SUCCESS: 'SENDOTP_SUCCESS',

  // USERCHECK AND RESENDTOP
  USERCHECK_REQUEST: 'USERCHECK_REQUEST',
  USERCHECK_ERROR: 'USERCHECK_ERROR',
  USERCHECK_SUCCESS: 'USERCHECK_SUCCESS',

  // resetPassword
  RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
  RESETPASSWORD_ERROR: 'RESETPASSWORD_ERROR',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',

  // changePassword
  CHANGEPASSWORD_REQUEST: 'CHANGEPASSWORD_REQUEST',
  CHANGEPASSWORD_ERROR: 'CHANGEPASSWORD_ERROR',
  CHANGEPASSWORD_SUCCESS: 'CHANGEPASSWORD_SUCCESS'
}

const signIn = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SENDSIGNIN_REQUEST))
  return await serviceController(routes.signIn, data)
    .then(res => {
      if (res?.data?.code === 400 || res?.data?.type === "invalid_mobile_number" || res?.data?.type === "access_denied" || res?.data?.type === 'need_to_approve_by_admin') {
        return res.data
      } else {
        dispatch(ToDoSuccess(actionTypes.SENDSIGNIN_SUCCESS, res.data))
        authStore.setAuth(res.data)
        orderStore.removeOrder()
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SENDSIGNIN_ERROR, error.message)))
}

const faceBookSignIn = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.FACEBOOKSIGNIN_REQUEST))
  return await serviceController(routes.faceBookSignIn, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.FACEBOOKSIGNIN_SUCCESS, res.data))
        authStore.setAuth(res.data.result)
        orderStore.removeOrder()
        return res.data
      } else {
        dispatch(ToDoError(actionTypes.FACEBOOKSIGNIN_ERROR, res.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.FACEBOOKSIGNIN_ERROR, error.message)))
}

const signUp = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SENDSIGNUP_REQUEST))
  return await serviceController(routes.signUp, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.SENDSIGNUP_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SENDSIGNUP_ERROR, error.message)))
}

const userOtp = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SENDOTP_REQUEST))
  return await serviceController(routes.userOtp, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.SENDOTP_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SENDOTP_ERROR, error.message)))
}

const userCheck = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.USERCHECK_REQUEST))
  return await serviceController(routes.resendOtp, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.USERCHECK_SUCCESS, res.data))
        return res.data.result
      } else {
        return res.data.result
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.USERCHECK_ERROR, error.message)))
}

const resetPassword = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.RESETPASSWORD_REQUEST))
  return await serviceController(routes.resetPassword, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.RESETPASSWORD_SUCCESS, res.data))
        return res.data.result
      } else {
        return res.data.result
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.RESETPASSWORD_ERROR, error.message)))
}

const changePassword = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CHANGEPASSWORD_REQUEST))
  return await serviceController(routes.changePassword, data)
    .then(res => {
      if (res?.data?.result?.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.CHANGEPASSWORD_SUCCESS, res.data))
        return res.data.result
      } else {
        return res.data.result
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CHANGEPASSWORD_ERROR, error.message)))
}

const signOut = () => async dispatch => {
  dispatch(ToDoSuccess(actionTypes.SENDSIGNIN_SUCCESS, null))
  authStore.removeAuth()
}

export const auth = {
  signIn,
  faceBookSignIn,
  signUp,
  userOtp,
  userCheck,
  resetPassword,
  changePassword,
  signOut
}