export const configKey = {
  // url develop
  // apiUrl: 'http://zizawaapi.innovix-solutions.net/',

  // url production
  // apiUrl: 'http://admin.medicalmyanmar.com/',

  // uat production
  // apiUrl: 'http://zizawaadmin.innovix-solutions.net/',
  //UATDomain: process.env.REACT_APP_UAT_DOMAIN,

  facebookAppId: '1100623483732378',
  messengerPageId: '1130386523834702',
  messengerAppId: '900227743904184',

  googleclientId:
    "1017036782317-ei6bhobvnuq7alvtf98844ipp07m7fva.apps.googleusercontent.com",
  googleMapKey: "AIzaSyAC4zZkHZYUto-qvsVig7XvTAdz9qb2wNM",
  // googleMapKey: 'AIzaSyC5i0mt34lAKRc2J59PlC-M9T6TPyk817g', // mind

  reCaptchaPublicKey: "6LdKjeUZAAAAAMXN4PQLJaD6q13U3Xud28CknumL",
  reCaptchaSecretKey: "6LdKjeUZAAAAAPAX7T3R9MeNwrxaV_rZXqec408h",

  db: "acs_server_testing",
}