const colors = {
  primary: '#1E91D6',
  secondary: '#2E3192',
  success: '#155724',
  danger: '#FF4B4B',
  warning: '#856404',
  warning1: '#ffbb33',
  info: '#333333',
  notiInfo: '#0c5460',
  light: '#EEEEEE',
  dark: '#39311d',
  smoke: '#f6f6f6',
  grey: '#9C9C9C',
  muted: '#7E7E7E',
  white: '#ffffff',
  black: '#000000',
  footer: '#E9EAF4',
  bgColor: '#EEEEEE',
  inputColor: '#999999',
  inputBorder: '#ced4da',
  label: '#555555',
  link: '#007bff',
  filterBorder: '#DDDDDD',
  fb: '#3B5998',

  // general color
  alertSuccess: '#d1e7dd',
  alertWarning: '#fff3cd',
  alertDanger: '#f8d7da',
  alertInfo: '#cff4fc'
}

export default colors
