import httpClient from './constant/HttpClient'
const queryString = require('query-string')

export * from './constant/Config'
export * from './constant/Routes'

export const routeFilter = params => {
  let str = queryString.stringify(params)
  return str !== '' ? `?${str}` : str
}

const controller = async (endpoint, ...data) => {
  let tmp = endpoint.split(':')
  return await httpClient[tmp[0]](tmp[1], ...data)
    .then(res => res && res)
    .catch(error => error)
}

export default controller