import { actionTypes } from '../actions/auth.action'
import { authStore } from '../../service'

const initialState = {
  error: null,
  isLoading: false,
  sentSignIn_data:  authStore.getAuth() || null,
  sentSignUp_data: null,
  userCheck_data: null,
  resetPassword_data: null,
  changePassword_data: null
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SENDSIGNIN_REQUEST: // SENDSIGNIN
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SENDSIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sentSignIn_data: action.data
      }
    case actionTypes.SENDSIGNIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.SENDSIGNUP_REQUEST: // SENDSIGNUP
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SENDSIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sentSignUp_data: action.data
      }
    case actionTypes.SENDSIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.USERCHECK_REQUEST: // USERCHECK
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.USERCHECK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userCheck_data: action.data
      }
    case actionTypes.USERCHECK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.RESETPASSWORD_REQUEST: // RESETPASSWORD
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.RESETPASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetPassword_data: action.data
      }
    case actionTypes.RESETPASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CHANGEPASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default auth