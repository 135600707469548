import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

import { isBetweenDateRange, calcuPromo, routeFilter } from '../../utils'

export const actionTypes = {
  // getPromotion
  GETPROMOTION_REQUEST: 'GETPROMOTION_REQUEST',
  GETPROMOTION_ERROR: 'GETPROMOTION_ERROR',
  GETPROMOTION_SUCCESS: 'GETPROMOTION_SUCCESS',

  // getPromotionById
  GETPROMOTIONBYID_REQUEST: 'GETPROMOTIONBYID_REQUEST',
  GETPROMOTIONBYID_ERROR: 'GETPROMOTIONBYID_ERROR',
  GETPROMOTIONBYID_SUCCESS: 'GETPROMOTIONBYID_SUCCESS',

  // getFixedPricePromotion
  GETFIXEDPRICEPROMOTION_REQUEST: 'GETFIXEDPRICEPROMOTION_REQUEST',
  GETFIXEDPRICEPROMOTION_ERROR: 'GETFIXEDPRICEPROMOTION_ERROR',
  GETFIXEDPRICEPROMOTION_SUCCESS: 'GETFIXEDPRICEPROMOTION_SUCCESS',
}

const getPromotion = (lang, division_id, home_page_status, customer_type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTION_REQUEST))
  return await serviceController(`${routes.getPromotion}?lang=${lang}&division_id=${division_id}&home_page_status=${home_page_status}&customer_type=${customer_type}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let tmp = await isBetweenDateRange(res.data.data)
        let final_res = {
          ...res.data,
          data: tmp
        }
        dispatch(ToDoSuccess(actionTypes.GETPROMOTION_SUCCESS, final_res))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTION_ERROR, error.message)))
}

const getPromotionById = (page_number, product_per_page, lang, promotion_listing_id, customer_type, pricelist_id, type) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTIONBYID_REQUEST))
  let no_package_promo = `${routes.getPromotionById}?page_number=${page_number}&product_per_page=${product_per_page}&lang=${lang}&promotion_listing_id=${promotion_listing_id}&customer_type=${customer_type}&pricelist_id=${pricelist_id}`
  let package_promo = `${routes.getPackagePromotionDetail}?page_number=${page_number}&product_per_page=${product_per_page}&lang=${lang}&promotion_listing_id=${promotion_listing_id}&customer_type=${customer_type}&pricelist_id=${pricelist_id}`
  let fixedPrice_promo = `${routes.getFixedPricePromo}?page_number=${page_number}&product_per_page=${product_per_page}&lang=${lang}&promotion_listing_id=${promotion_listing_id}&customer_type=${customer_type}&pricelist_id=${pricelist_id}`
  return await serviceController(type === 'packagediscount' ? package_promo : type === 'fixedpricepromotion' ? fixedPrice_promo : no_package_promo)
    .then(async res => {
      if (res.data.data.error) {
        // console.log(res.data)
        return res.data
      } else {
        if (type !== 'packagediscount') {
          let tmp = await calcuPromo(res?.data?.data)
          tmp?.product_list?.length > 0 && tmp?.product_list?.map(x => x.qty = 1)
        } else {
          res?.data?.data?.product_list?.length > 0 && res?.data?.data?.product_list?.map(x => x.qty = 1)
        }
        dispatch(ToDoSuccess(actionTypes.GETPROMOTIONBYID_SUCCESS, res.data.data))
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTIONBYID_ERROR, error.message)))
}

const getFixedPricePromo = params => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTIONBYID_REQUEST))
  return await serviceController(`${routes.getFixedPricePromo}?${routeFilter(params)}`)
    .then(async res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        let tmp = await calcuPromo(res?.data?.data)
        dispatch(ToDoSuccess(actionTypes.GETPROMOTIONBYID_SUCCESS, tmp))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTIONBYID_ERROR, error.message)))
}

const checkPromoProductQty = (product_id, variant_id, qty, promo_product) => async dispatch => {
  return await serviceController(`${routes.checkProductQty}?product_id=${product_id}&variant_id=${variant_id}&qty=${qty}`)
    .then(res => {
      if (res?.data?.data[0]?.instock) {
        if (promo_product) {
          let tmp = promo_product?.product_list?.filter(x => x.product_id === product_id).map(y => (y.qty = qty, y.status = 'in_stock'))
          dispatch(ToDoSuccess(actionTypes.GETPROMOTIONBYID_SUCCESS, promo_product))
        }
      } else {
        if (promo_product) {
          let tmp = promo_product?.product_list?.filter(x => x.product_id === product_id).map(y => (y.qty = qty, y.status = 'out_of_stock'))
          dispatch(ToDoSuccess(actionTypes.GETPROMOTIONBYID_SUCCESS, promo_product))
        }
      }
    })
    .catch(error => console.log('error promo qty change', error.message))
}

export const Promotion = {
  getPromotion,
  getPromotionById,
  getFixedPricePromo,
  checkPromoProductQty
}