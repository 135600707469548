import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  ABOUTUS: 'ABOUTUS',

  // getAboutUs
  GETABOUTUS_REQUEST: 'GETABOUTUS_REQUEST',
  GETABOUTUS_ERROR: 'GETABOUTUS_ERROR',
  GETABOUTUS_SUCCESS: 'GETABOUTUS_SUCCESS'
}

const getAboutUs = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETABOUTUS_REQUEST))
  return await serviceController(`${routes.getAboutUs}?lang=${lang}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETABOUTUS_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETABOUTUS_ERROR, error.message)))
}

export const aboutUs = {
  getAboutUs
}