import styled from 'styled-components'
import loadingImg from '../assets/images/loading.gif'
import colors from './constant/Color'

const PageLoading = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
  p {
    font-size: 12px;
  }
`

const SubmittingLoading = styled.div`
  display: flex;
  justify-content: center;

  &.custom{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;

    span{
      background-color: #2E3192;
    }
  }

  @keyframes bounce {
    to {
      opacity: .3;
      transform: translate3d(0, -4px, 0);
    }
  }
`

const Dot = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${colors.white};
  animation: 1s bounce infinite alternate;
  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(2) {
    animation-delay: .3s;
  }

  &:nth-child(3) {
    animation-delay: .6s;
  }
`

const Loading = props => {
  const { text, pageLoading } = props
  return (
    <>
      {pageLoading ?
        <PageLoading>
          <img src={loadingImg} alt="app loading" />
          {text && <p>{text}</p>}
        </PageLoading> :
        <SubmittingLoading {...props}>
          <Dot />
          <Dot />
          <Dot />
        </SubmittingLoading>
      }
    </>
  )
}

export default Loading