import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

import { checkChange, getCheckId } from '../../utils'

export const actionTypes = {
  GETBRAND_REQUEST: 'GETBRAND_REQUEST',
  GETBRAND_ERROR: 'GETBRAND_ERROR',
  GETBRAND_SUCCESS: 'GETBRAND_SUCCESS'
}

const getProductBrand = (lang, category_id, keyword, customer_type, division_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBRAND_REQUEST))
  return await serviceController(`${routes.getProductBrand}?lang=${lang}&category_id=${category_id}&keywords=${keyword}&customer_type=${customer_type}&division_id=${division_id}`)
    .then(res => {
      if (res.data.data.error) {
        console.log(res.data)
      } else {
        dispatch(ToDoSuccess(actionTypes.GETBRAND_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBRAND_ERROR, error.message)))
}

const getCheckChange = (type, array, id) => async dispatch => {
  let res = await checkChange(type, array, id)
  let id_res = await getCheckId(type, res)
  let data = {
    count: res?.length,
    brand_id: id_res,
    data: res
  }
  dispatch(ToDoSuccess(actionTypes.GETBRAND_SUCCESS, data))
}

export const brand = {
  getProductBrand,
  getCheckChange
}