import serviceController, { routes } from '../../controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  PROFILE: 'PROFILE',

  // getPROFILE
  GETPROFILE_REQUEST: 'GETPROFILE_REQUEST',
  GETPROFILE_ERROR: 'GETPROFILE_ERROR',
  GETPROFILE_SUCCESS: 'GETPROFILE_SUCCESS',

  // sentPROFILE
  UPDATEPROFILE_REQUEST: 'UPDATEPROFILE_REQUEST',
  UPDATEPROFILE_ERROR: 'UPDATEPROFILE_ERROR',
  UPDATEPROFILE_SUCCESS: 'UPDATEPROFILE_SUCCESS'
}

const getProfile = (customer_id) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROFILE_REQUEST))
  return await serviceController(`${routes.getProfile}?customer_id=${customer_id}`)
    .then(res => {
      if (res?.data?.data?.code === "token") {
        dispatch(ToDoError(actionTypes.GETPROFILE_ERROR, res?.data?.data?.code))
      } else {
        dispatch(ToDoSuccess(actionTypes.GETPROFILE_SUCCESS, res.data))
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROFILE_ERROR, error.message)))
}

const updateProfile = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATEPROFILE_REQUEST))
  return await serviceController(routes.updateProfile, data)
    .then(res => {
      if (res.data.result.status === 'success') {
        dispatch(ToDoSuccess(actionTypes.UPDATEPROFILE_SUCCESS, res.data))
        return res.data
      } else {
        return res.data
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATEPROFILE_ERROR, error.message)))
}

const cleanData = (type, data) => {
  return ({
    type,
    data
  })
}

export const profile = {
  getProfile,
  updateProfile,
  cleanData
}